import React from 'react'
import Navbar from '../Components/Navbar.js'



const Industrial1= process.env.PUBLIC_URL + '/assets/images/industrial1.png';
const Industrial2= process.env.PUBLIC_URL + '/assets/images/industrial2.png';
const Industrial3= process.env.PUBLIC_URL + '/assets/images/industrial3.png';
const blue= process.env.PUBLIC_URL + '/assets/images/bluenile.png';






const Bluenile = () => {
  return (
    <div>
        <div className=' font-poppins'>
            <Navbar/>

            <div className='py-10 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 
                            lg:gap-0  bg-[white] text-black'>
               <div className='relative inline uppercase '>
                   <h1 className='font-bold text-[24px]'>BlueNile: Research & Data Visualisation of the 
                       Kwara State Industrial Park</h1>
              </div>
         </div>
           <div className="w-full ] py-10 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-2 " src={blue} alt="/"/>
                 <img className="w-[400px] mx-auto my-2 " src={Industrial1} alt="/"/>
                 <img className="w-[400px] mx-auto my-2 " src={Industrial2} alt="/"/>
                 <img className="w-[400px] mx-auto my-2 " src={Industrial3} alt="/"/>
    <div className="text-black flex  flex-col justify-center my-5 px-20">
                 <p className='py-1'>
                      <span className="text-black">Industry/Sector:</span> <span className="text-[#e7d7c1] font-bold">Public Sector</span>
                 </p>
                <p className='py-1'>
                      <span className="text-black">Launched:</span> <span className="text-[#e7d7c1] font-bold">Year 2023</span>
                 </p>
                 <h1 className='font-bold text-[24px]  py-5'>Results
                </h1>
                </div>
                </div>
             <div className='px-20'>
      <ul className="list-disc">
        <li> Effective problem-solving by providing practical 
                      solutions that meet user and stakeholder needs</li>
        <li>  Impactful visual communication that effectively 
               conveys messages and captures audience attention.    
        </li>
        <li  > Strong brand identity, differentiating the brand and 
                establishing a prominent market presence.
        </li>
     <li>   Seamless functionality, offering user-friendly and 
            efficient experiences across products and platforms.   </li>
                <li>
                  Environmental sustainability, reducing impact and 
                    promoting eco-friendly practices.
                </li>
                <li>Effective collaboration, facilitating smooth workflows 
                    and successful project delivery.
                  
                </li>
                <li>Timely and cost-effective delivery, meeting project 
                    goals within allocated resources and timelines.</li>
                <li>Measurable success, allowing tracking and evaluation 
                   of project impact for future improvements.</li>
      </ul>
             </div>      
                 <div className=' my-4 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 px-20'>
          
            <div>
              <h1 className='font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Project Management</li>
        <li>Inclusive / Interactive Design</li>
        <li>Research & Analytics</li>
               <li>Data Visualisation</li>
                <li>
                 Print Design
                </li>
               
      </ul>
    </div>
        </div>
             </div>
             
                 
                
             </div> 
             
             

    
    </div>
  )
}

export default Bluenile;