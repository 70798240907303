import React from "react";
import Slider from "react-slick";
import Navbar from "./Navbar";
import Clientele from "./Clientele";
import Card from "./Card";
import Newsletter from "./Newsletter";
import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const logo= process.env.PUBLIC_URL + '/assets/images/Cover1.png';

const Hero = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div>
            
            <div className="font-poppins">
                 <Navbar />
                 <Link to="/" className="flex items-center justify-center h-screen w-full">
                   
                <img className="  object-cover h-full w-full flex items-center justify-center" src={logo} alt="/" />
                        </Link>
                         
               
                
            </div>
            <Clientele />
            <Card />
            <Newsletter />
        </div>
    );
};

export default Hero;
