import React from 'react'
import { Link } from 'react-router-dom';
import {FaFacebookSquare,
    FaInstagram,
    FaLinkedin,
    FaTwitterSquare} from 'react-icons/fa'


const logo2= process.env.PUBLIC_URL + '/assets/images/logo2.png';


const Footer = () => {
  return (
    <div className='  font-poppins max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
       <div>
              <Link to="/">
                <img className="w-[100px] mx-4 my-4 flex items-center" src={logo2} alt="/" />
               </Link>
              <p className=' w-full  m-4 mx-4 my-2 flex items-center'>
                Crafting a Future of Possibilities.</p>
       <div className='m-4 flex  justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30}/>
                  <FaInstagram size={30} />
                  <FaLinkedin size={30} />
                  <FaTwitterSquare size={30} />
       </div>
              <div className=' className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]'>
                  <p className='font-poppins font-normal text-center text-[18px] leading-[27px] text-gray-300' >
                      2023 Asteria Alliance Limited. All Rights Reserved
                  </p>

              </div>
       </div>
    </div>
  )
}

export default Footer