import React from 'react'
import Navbar from '../Components/Navbar.js'


const sebore1= process.env.PUBLIC_URL + '/assets/images/sebore1.png';
const sebore2= process.env.PUBLIC_URL + '/assets/images/sebore2.png';
const seboremain= process.env.PUBLIC_URL + '/assets/images/seboremain.png';





const Sebore = () => {
  return (
    <div>
        <div className=' font-poppins'>
            <Navbar/>

            <div className='py-10 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 
                            lg:gap-0  bg-[white] text-black'>
               <div className='relative inline uppercase '>
                   <h1 className='font-bold text-[24px]'>Sebore: Development of an Agricultural 
                     Chain Business Plan, Product Development, 
                     Communication</h1>
              </div>
         </div>
           <div className="w-full ] py-10 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-2 " src={seboremain} alt="/"/>
                 <div className="text-black flex  flex-col justify-center my-5">
                 <p className='py-1'> Industry / Sector: Public Sector
                 </p>
                 <p className='py-1'>Launched: Year 2021 - Date </p>
                 <p className='py-1'>https:seboregroup.com</p>
                 <h1 className='font-bold text-[24px]  py-5'>Results
                </h1>
             <div>
      <ul className="list-disc">
        <li>Clear direction for achieving goals and guiding 
                company actions 
        </li>
        <li>  
            Informed decision-making based on valuable 
                 insights from market trends and analysis.
         </li>
         
         <li>Efficient allocation of resources for maximizing 
               utilization towards business objectives.
               </li>
        <li>  Streamlined processes and strategies leading to 
                  improved efficiency and productivity.
        </li>
        <li>Ongoing adaptation, improvement, and 
                 refinement of strategies to align with market 
                  changes
        </li>
    
           
      </ul>
             </div>      
                 <div className=' my-4 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 '>
          
            <div>
              <h1 className='font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Website and Mobile Development</li>
        <li>Digital Experience Management</li>
        <li>Technology Governance</li>
               <li>Customer Data Platforms</li>
            
      </ul>
    </div>
        </div>
             </div>
             
                  <img className="w-[400px] mx-auto my-4 " src={sebore1} alt=""/>
                  <img className="w-[400px] mx-auto my-4 " src={sebore2} alt=""/>
             </div> 
             
             </div>

  </div>      
    </div>
  )
}

export default Sebore;