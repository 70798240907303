import React from 'react'
import Navbar from '../Components/Navbar.js'


const eagle1= process.env.PUBLIC_URL + '/assets/images/eagle1.png';
const eagle2= process.env.PUBLIC_URL + '/assets/images/eagle2.png';



const Eagleview = () => {
  return (
    <div>
        <div className=' font-poppins'>
            <Navbar/>

            <div className='py-10 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 
                            lg:gap-0  bg-[white] text-black'>
               <div className='relative inline uppercase '>
                   <h1 className='font-bold text-[24px]'>EagleView Security: ERP System</h1>
              </div>
         </div>
           <div className="w-full ] py-10 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-2 " src={eagle1} alt="/"/>
                 <img className="w-[400px] mx-auto my-2 " src={eagle2} alt="/"/>
    <div className="text-black flex  flex-col justify-center my-5 px-20">
                 <p className='py-1'>
                      <span className="text-black">Industry/Sector:</span> <span className="text-[#e7d7c1] font-bold">Private Sector</span>
                 </p>
                <p className='py-1'>
                      <span className="text-black">Launched:</span> <span className="text-[#e7d7c1] font-bold">Year 2020</span>
                 </p>
                 <p className='py-1'>
                      <span className="text-black">www.eagleview.com.ng</span> 
                 </p>
                 <h1 className='font-bold text-[24px]  py-5'>Results
                </h1>
                </div>
                </div>
             <div className='px-20'>
      <ul className="list-disc">
        <li> Digital Presence for the EagleView Security Company</li>
        <li>  Rapid Security Deployment System    
        </li>
        <li  > Information about Programs
        </li>
     <li>  Emergency System  </li>
                <li>
                 Social media feeds integration
                </li>
                <li>Photo Gallery
                </li>
                <li>FAQ and Blog section</li>
                
      </ul>
             </div>      
                 <div className=' my-4 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 px-20'>
          
            <div>
              <h1 className='font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Web Development</li>
        <li>Mobile & Interactive</li>
        <li>Web Management</li>
               <li>Managed IT services</li>
                
      </ul>
    </div>
        </div>
             </div>
             
                 
                
             </div> 
             
             

    
    </div>
  )
}

export default Eagleview;