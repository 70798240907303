import React, { useState } from 'react';
import Navbar from '../Components/Navbar.js';

import { Link } from 'react-router-dom';


const aqualandia= process.env.PUBLIC_URL + '/assets/images/aqualandia.png';

const Bluenile = process.env.PUBLIC_URL + '/assets/images/bluenile.png';
const sebore = process.env.PUBLIC_URL + '/assets/images/sebore.png';



const Culture = [
  
  {
    imageSrc: aqualandia,
    title: '',
    route: '/Pages/Aqualandia',
  },
  {
    imageSrc: sebore,
    title: '',
    route: '/Pages/Sebore',
  },
  {
    imageSrc: Bluenile,
    title: '',
    route: '/Pages/Bluenile',
  },
  
];

const ArtAndCulture = () => {
  let gridCols = 3;
  let justifyClass = 'justify-center';

  return (
    <div className=' font-poppins'>
       <Navbar />

      <div className="flex items-center justify-center h-20 max-w-[1240px] mx-auto px-4 space-x-8"></div>
      <div className="flex min-h-screen items-center justify-center py-5 bg-white">
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-${gridCols} gap-4 ${justifyClass}`}>
          {Culture.map((item, index) => (
            <Link to={item.route} key={index} className="group relative cursor-pointer transition-shadow hover:shadow-xl hover:shadow-black/30">
              <h1 className="font-dmserif text-xl h-20 px-10 font-bold text-black text-center">{item.title}</h1>
              <div className="h-40 w-40 mx-auto">
                <img className="h-full w-full object-cover" src={item.imageSrc} alt="" />
              </div>
              <div className="absolute inset-0 from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArtAndCulture;
