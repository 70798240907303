import React from 'react'
import Navbar from '../Components/Navbar.js'


const architect = process.env.PUBLIC_URL + '/assets/images/architect.png';



const Architecture = () => {
  return (
    <div>
        <div className=' font-poppins'>
            <Navbar/>

            <div className='py-10 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 
                            lg:gap-0  bg-[white] text-black'>
               <div className='relative inline uppercase '>
                   <h1 className='font-bold text-[24px]'>Architects Registration Council of 
                       Nigeria: Architects Colloquium</h1>
              </div>
         </div>
           <div className="w-full ] py-10 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-2 " src={architect} alt="/"/>
                
    <div className="text-black flex  flex-col justify-center my-5 px-20">
                 <p className='py-1'>
                      <span className="text-black">Industry/Sector:</span> <span className="text-[#e7d7c1] font-bold">Public Sector</span>
                 </p>
                <p className='py-1'>
                      <span className="text-black">Launched:</span> <span className="text-[#e7d7c1] font-bold">Year 2021 & 2022</span>
                 </p>
                 <p className='py-1'>
                      <span className="text-black">www.architectscolloquium.com.ng</span> 
                 </p>
                 <h1 className='font-bold text-[24px]  py-5'>Results
                </h1>
                
             <div className='px-20'>
      <ul className="list-disc">
        <li> Summit Event Organisation</li>
        <li>  Developed 3D floor plan for summit area
        </li>
        <li >Designed, print and set up various signage around 
                summit area
        </li>
        <li>Set up of registration and staging area    </li>
                <li>
                 Design and set up of lighting, sound and video
                </li>
                <li>Managed delivery of AV and technical services 
                     throughout summit agenda
                </li>
                <li>Developed 3 minute video summary for each day‘s 
                       activities</li>
                <li>Managed AV and technical staff to support on 
                     deliverables</li>
                <li>Set up and breakdown of technical services on date 
                   and on time.</li>
                
      </ul>
             </div>      
                
             </div>
             
                 
                
             </div> 
             
             

    
    </div>
     <div className=' my-8 grid grid-cols-1 lg:grid-cols-2 gap-8 py-10 lg:gap-0 px-40'>
          
            <div>
              <h1 className='font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Web  & Development</li>
        <li>Mobile & Interactive</li>
        <li>Web Management</li>
               <li>Event Organization</li>
               <li>Research & Analytics</li>
               <li>Digitisation</li>
               <li>Strategic communications</li>
                
      </ul>
    </div>
        </div>
    </div>
    
    </div>
  )
}

export default Architecture;