import React from 'react'
import Navbar from '../Components/Navbar.js'


const teamoffice= process.env.PUBLIC_URL + '/assets/images/teamoffice.jpg';
const staff= process.env.PUBLIC_URL + '/assets/images/staff.jpg';
const Desk= process.env.PUBLIC_URL + '/assets/images/desk.jpg';



const Careers = () => {
  return (
    <div className=' font-poppins'>
        <Navbar/>
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-10 
                        px-20 ml-auto mx-auto grid md:grid-cols-
                        2">
            Careers
        </h1>
        <p className="max-w-[1240px] px-20  flex flex-col md:flex-row md:w-1/2">At Asteria, we offer a wide range of career opportunities for talented individuals who are 
            passionate about technology, innovation, and customer service. Our employees are at 
            the heart of our business, and we are committed to providing them with the necessary 
            support, training, and development they need to succeed.
        </p>
        <p className="max-w-[1240px] py-8 px-20 flex flex-col md:flex-row md:w-1/2">Our company culture is centered around teamwork, collaboration, and respect. We 
           believe in recognizing and rewarding our employees for their hard work and dedication. 
           We are always looking for talented individuals to join our team and help us achieve our 
           goals. Our work makes a difference in communities worldwide, and if you are looking for 
           a career in IT consulting and management, we would love to hear from you.
           </p>
            <div className="mx-auto grid md:grid-cols-2">
           <img className="w-[400px] mx-auto my-4 " src={staff} alt="/"/>
          
           <div className="">
            <img className="w-[500px] mx-auto my-40 " src={teamoffice} alt="/" />
           </div>
            </div>

        <div>
          
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-10 
                        px-20 ml-auto mx-auto grid md:grid-cols-
                        2">
            What it's like to work here
        </h1>
        </div>
        <div className="max-w-[1240px] flex flex-col md:flex-row mx-auto">
  <div className="w-full md:w-1/2 px-4 md:order-2">
    <img className="w-full mx-auto md:float-right" src={Desk} alt="/" />
  </div>
  <div className="w-full md:w-1/2 px-4 md:order-1">
    <p className="py-8">
      Asteria’s company culture is centered around teamwork, collaboration, and respect. We believe that our employees are our greatest asset, and we strive to create a work environment that is supportive and inclusive. Our team is made up of individuals who are passionate about technology and are committed to providing the best possible solutions to our clients.
    </p>
    <p className="py-8">
      At Asteria, you will have the opportunity to work on a variety of projects, from small-scale implementations to large-scale enterprise solutions. You will work alongside some of the brightest minds in the industry and have access to the latest technology and tools. We believe in investing in our employees and providing them with the training and resources they need to succeed.
    </p>
  </div>
</div>

         
    </div>
  )
}

export default Careers