import React from 'react';


const Cards = () => {
  return (
    <div className='  font-poppins w-full py-[10rem] px-4 bg-white'>
        <p className="text-[#0077b6] flex flex-col justify-center py-8 items-center md:text-4xl sm:text-3xl text-2xl font-bold ">
            WHY CHOOSE US?</p>

      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8  text-black'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              
              <h2 className='text-2xl font-bold text-center py-8'>Professionalism And Expert Knowledge</h2>
              <p className='text-center text-4xl font-bold'>Excellence in Every Detail</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Dedication Beyond Measure</p>
                  <p className='py-2 border-b mx-8'>Unparalleled Know-Howr</p>
                  <p className='py-2 border-b mx-8'>Unmatched Precision</p>
              </div>
              
          </div>
          <div className='w-full shadow-xl text-black bg-white flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
            
              <h2 className='text-2xl font-bold text-center py-8'>Proven Track Record</h2>
              <p className='text-center text-4xl font-bold'>Trusted by Many, Proven by All</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Results-Driven, Proven-Tested</p>
                  <p className='py-2 border-b mx-8'>Quality, Reliability,</p>
                  <p className='py-2 border-b mx-8'>Our Success Stories, Tailored to Your Ambitions</p>
              </div>
            
          </div>
          <div className='w-full shadow-xl  flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
           
              <h2 className='text-2xl font-bold text-center py-8'>Tailored To Your Brand</h2>
              <p className='text-center text-4xl '>Flexible Solutions and Adaptability</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Personalized Customer Experiences</p>
                  <p className='py-2 border-b mx-8'>Collaborative Approach</p>
                  <p className='py-2 border-b mx-8'>Personalized Customer Experiences</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Cards;