import React from 'react';

import Navbar from './Navbar.js';


const shifu= process.env.PUBLIC_URL + '/assets/images/shifu.jpg';
const auma= process.env.PUBLIC_URL + '/assets/images/auma.jpg';
const footmark= process.env.PUBLIC_URL + '/assets/images/footmarks.jpg';
const bolts= process.env.PUBLIC_URL + '/assets/images/bolts.png';

  const team = [
    { name: 'Abdulhamid', role: 'Director', imageUrl: '/path/to/abdulhamid.jpg' },
    { name: 'Zubair', role: 'Director', imageUrl: '/path/to/zubair.jpg' },
    { name: 'Austin', role: 'Designer', imageUrl: '/path/to/austin.jpg' },
    { name: 'Auma', role: 'Executive Support Specialist', imageUrl: auma },
    { name: 'Michael', role: 'Frontend Developer', imageUrl:shifu },
  ];
const About = () => {
  return (
    <div className=' font-poppins'>
      
    <div className=' bg-[#353535]'>
        <Navbar />
        <div className='text-white'>
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-10 
                        px-20 ml-auto mx-auto grid md:grid-cols-2">
          About Asteria Alliance</h1>
<div className="max-w-[1240px] mx-auto flex flex-col md:flex-row">
  <div className="md:w-1/2">
 <p className=''>
          We are committed to making a positive impact on the world through our expertise and 
resources. Our company is all about turning ideas into impact. Our goal is to provide 
unbeatable quality and exceptional value to our clients and make a difference in the 
world</p>
</div>
 <div className="md:w-1/2">
    <img className="w-[500px] mx-auto my-4 md:float-right" src={footmark} alt="/" />
  </div>
  </div>      
</div>  

 <div className='text-white'>
        <h1 className="md:text-4xl sm:text-3xl text-2xl 
        font-bold py-10 px-20 ml-auto mx-auto grid md:grid-cols-2">
          Turning ideas into impact</h1>
<div className="max-w-[1240px] mx-auto flex flex-col md:flex-row">
  <div className="md:w-1/2 px-4">
 <p className=''>
          Asteria Alliance is committed to using its expertise and resources to make a positive impact 
         on the world. Through our work, We are contributing to the development of emerging economies and creating
         a more sustainable and inclusive future for all</p>
</div>

  </div>      
</div>  

 <div className="w-full ] py-16 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-4 " src={bolts} alt="/"/>
                <div className="text-white flex  flex-col justify-center">
                 <p className=" font-bold">Our People</p>
                 <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">At Asteria Alliance</h1>
                 <p>We have a unified management team, talent pool, knowledge, and resources, as well as a 
                    set of tools, methodologies, and training that ensure consistent services are delivered to clients worldwide.
                    When selecting staff for a project,
                     Asteria draws from its personnel resource 
                  centers to ensure the deployment of the right expertise for the job.
                 </p>
                 
             </div>
             </div> 

  </div>      
</div>  
{/*
 <div className='flex min-h-screen flex-wrap justify-center bg-[#353535]'>
      {team.map((member, index) => (
        <div
          key={index}
          className='flex min-h-screen flex-col justify-center m-4'
        >
          <div className='group h-96 w-96 perspective-1000px'>
            <div className='relative h-full w-full rounded-xl 
            shadow-xl transform-style-preserve-3d transition-transform duration-500'>
              <div className='absolute inset-0'>
                <img
                  className='h-full w-full rounded-xl object-cover shadow-xl shadow-black/40
                  transition-shadow group-hover:shadow-2xl
                  '
                  src={member.imageUrl} 
                  alt=''
                />
                
              </div>
              <div className='absolute inset-0 h-full w-full rounded-xl bg-black/80 text-center text-white transform rotateY-180
               backface-visibility-hidden transition-opacity duration-300 group-hover:opacity-0'>
                <div className='flex h-full flex-col items-center justify-center'>
                  <div>
                    <h1 className='text-3xl font-bold'>{member.name}</h1>
                    <p className='text-lg'>{member.role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      ))}
      </div>
      */}
      {/* line of code for staff data */}
    </div>
     
       
  );
};

export default About;