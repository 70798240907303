import React, { useState } from 'react';
import Navbar from './Navbar.js';

import { Link } from 'react-router-dom';

const iaaf = process.env.PUBLIC_URL + '/assets/images/Iaaf.png';
const aqualandia= process.env.PUBLIC_URL + '/assets/images/aqualandia.png';
const kwara = process.env.PUBLIC_URL + '/assets/images/kwara.png';
const Bluenile = process.env.PUBLIC_URL + '/assets/images/bluenile.png';
const sebore = process.env.PUBLIC_URL + '/assets/images/sebore.png';
const workpic = process.env.PUBLIC_URL + '/assets/images/workpic.jpg';
const eagleview = process.env.PUBLIC_URL + '/assets/images/eagleview.jpg';
const tech = process.env.PUBLIC_URL + '/assets/images/tech.jpg';






const projectsItems = [
  {
    imageSrc: kwara,
     
    title: ' All Projects',
    route: '/Pages/Architecture',
  },
  {
    imageSrc: iaaf,
    title: '',
    route: '/Pages/Iatf',
  },
  {
    imageSrc: aqualandia,
    title: '',
    route: '/Pages/Aqualandia',
  },
  {
    imageSrc: Bluenile,
    title: '',
    route: '/Pages/Bluenile',
  },
  {
    imageSrc: sebore,
    title: '',
    route: '/Pages/Sebore',
  },
  {
    imageSrc: workpic,
    title: '',
    route: '',
  },
  {
    imageSrc: eagleview,
    title: '',
    route: '/Pages/Eagleview',
  },
];
const industryItems =[

  {
    imageSrc: iaaf,
    title: 'Arts & culture',
    route: '/Projects/ArtAndCulture',
  },
  {
    imageSrc: aqualandia,
    title: 'Banking & Finance',
  },
  {
    imageSrc: kwara,
    title: 'Civic & Public',
    route: '/Projects/CivicPublic',
  },
];
const servicesItems =[
    {
    imageSrc: Bluenile,
    title: 'Strategy',
  },
  {
    imageSrc: sebore,
    title: 'Data',
  },
  {
    imageSrc: workpic,
    title: 'Design',
  },
  {
    imageSrc: tech,
    title: 'Technology',
  },

];



const Work = () => {
  const [activeTab, setActiveTab] = useState('INDUSTRY');
  

  let activeItems = [];
  let gridCols = 3;
  let justifyClass = 'justify-center';

  if (activeTab === 'INDUSTRY') {
    activeItems = industryItems;
  } else if (activeTab === 'SERVICES') {
    activeItems = servicesItems;
  } else if (activeTab === 'PROJECTS') {
    activeItems = projectsItems;
    gridCols = 4;
    justifyClass = 'justify-center xl:justify-start';
  }

  return (
    <div className=' font-poppins'>
      <Navbar />

      <div className="flex items-center justify-center h-20 max-w-[1240px] mx-auto px-4 space-x-8">
        <p
          className={`text-[#d9d9d9] hover:text-[#000814] text-[24px] cursor-pointer ${
            activeTab === 'INDUSTRY' ? 'text-[#000814]' : ''
          }`}
          onClick={() => setActiveTab('INDUSTRY')}
        >
          INDUSTRY
        </p>
        <p
          className={`text-[#d9d9d9] hover:text-[#000814] text-[24px] cursor-pointer ${
            activeTab === 'SERVICES' ? 'text-[#000814]' : ''
          }`}
          onClick={() => setActiveTab('SERVICES')}
        >
          SERVICES
        </p>
        <p
          className={`text-[#d9d9d9] hover:text-[#000814] text-[24px]  cursor-pointer ${
            activeTab === 'PROJECTS' ? 'text-[#000814]' : ''
          }`}
          onClick={() => setActiveTab('PROJECTS')}
        >
          PROJECTS
        </p>
      </div>
      <div className="flex min-h-screen  items-center justify-center py-5 bg-white">
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-${gridCols} gap-4 ${justifyClass}`}>
        {activeItems.map((item, index) => (
          
           <Link to={item.route} key={index} className="group relative cursor-pointer transition-shadow hover:shadow-xl hover:shadow-black/30">
             
             
              <h1 className="font-dmserif text-xl h-20 px-10 font-bold text-black text-center">{item.title}</h1>
              <div className="h-40 w-40 mx-auto">
                <img className="h-full w-full object-cover" src={item.imageSrc} alt="" />
              </div>
              <div className="absolute inset-0 from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
