import React from 'react'
import Navbar from '../Components/Navbar.js'


const aqua1 = process.env.PUBLIC_URL + '/assets/images/aqua1.png';
const aqua2= process.env.PUBLIC_URL + '/assets/images/aqua2.png';
const Aqualandia = () => {
  return (
    <div>
        <div className=' font-poppins'>
            <Navbar/>

            <div className='py-10 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 
                            lg:gap-0  bg-[white] text-black'>
               <div className='relative inline uppercase '>
                   <h1 className='font-bold text-[24px]'>Central Park: Aqualandia</h1>
              </div>
         </div>
           <div className="w-full ] py-10 px-4">
            <div className=" mx-auto grid md:grid-cols-2">
                 <img className="w-[400px] mx-auto my-2 " src={aqua1} alt="/"/>
                 <div className="text-black flex  flex-col justify-center my-5">
                 <p className='py-1'> Industry / Sector: Private Sector
                 </p>
                 <p className='py-1'>Launched: Year 2023</p>
                 <p className='py-1'>https://centralparkabuja.com</p>
                 <h1 className='font-bold text-[24px]  py-5'>Results
                </h1>
             <div>
      <ul className="list-disc">
        <li> Enhanced brand recognition through a distinctive and 
                 memorable brand identity, increasing visibility among the 
                 target audience.</li>
        <li>      Increased brand awareness as a cohesive visual brand 
                identity creates a consistent brand presence across channels.
        </li>
        <li  >  Improved brand differentiation by standing out from 
                    competitors, aiding customer distinction in the market.
        </li>
     <li>      Stronger emotional connection through visually appealing 
             and engaging brand design, fostering positive brand 
               experiences.</li>
                <li>
                  Consistent brand communication through guidelines and 
                   templates, ensuring a cohesive brand image and 
                   messaging.
                </li>
                <li>
                  Measurable impact by setting goals aligned with business 
                     objectives, allowing data-driven decisions for improvement.
                </li>
      </ul>
             </div>      
                 <div className=' my-4 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 '>
          
            <div>
              <h1 className='font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Research & Strategy Development</li>
        <li>Event Organisation</li>
        <li>Service Design</li>
               <li>Print Design</li>
                <li>
                  Analytics
                </li>
               
      </ul>
    </div>
        </div>
             </div>
             
                  <img className="w-[400px] mx-auto my-4 " src={aqua2} alt="/"/>
                
             </div> 
             
             </div>

  </div>      
    </div>
  )
}

export default Aqualandia