import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Components/About.js';

import Footer from './Components/Footer.js';

import Contact from './Components/Contact.js';

import Hero from './Components/Hero.js';

import Work from './Components/Work.js';
import Careers from './Pages/Careers.js';
import Iatf from './Pages/Iatf.js';

import Aqualandia from './Pages/Aqualandia.js';
import Bluenile from './Pages/Bluenile.js';
import Sebore from './Pages/Sebore.js';
import Eagleview from './Pages/Eagleview.js';
import Architecture from './Pages/Architect.js';
import CivicPublic from './Projects/CivicPublic.js';
import ArtAndCulture from './Projects/ArtAndCulture.js';


function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>

          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path='/Careers' element={<Careers/>}/>
          <Route path="/contact" element={<Contact />} />
          <Route path='/work' element={<Work/>}/>
          <Route path='/pages/Iatf' element={<Iatf/>}/>
          <Route path='/pages/Aqualandia' element={<Aqualandia/>}/>
          <Route path='/pages/Bluenile' element={<Bluenile/>}/>
          <Route path='/pages/Sebore'element={<Sebore/>}/>
          <Route path='/pages/Eagleview'element={<Eagleview/>}/>
          <Route path='/pages/Architecture' element={<Architecture/>}/>
          <Route path='/projects/CivicPublic'element={<CivicPublic/>}/>
          <Route path='/Projects/ArtAndCulture' element={<ArtAndCulture/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

