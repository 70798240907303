import React from 'react'

import Navbar from './Navbar.js';

const office= process.env.PUBLIC_URL + '/assets/images/contactoffice.jpg';

const Contact=()=> {
  return (
    <div className=' font-poppins'>
        <Navbar/>
      <div className='py-20 px-10 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0  bg-[white] text-black'>
        
        <div className='text-center text-black'>
            <h1 className='relative inline uppercase font-bold text-[24px]'>
                Contact Us
                <span className='absolute left-1/2 bg-gray-500 -bottom-2 -translate-x-1/2 h-[1px] w-[60px]'></span>
            </h1>
            <div>
                  <img className="w-[350px] mx-auto my-4 " src={office} alt="/" />
            </div>
        </div>
          <div className='py-20 px-20'>
                <p>
                  Asteria Alliance Limited <br />
                  16 Bello Yusuf Street, Jabi, <br />
                  Abuja 900211. <br />
                  asteriaalliance@gmail.com <br/>
                  +(234) 902 636 2304 <br />
                  +(234) 909 707 7007
                </p>
          </div>
            <form className='flex flex-col justify-center items-center mx-auto py-20'>
    <div className='flex flex-col w-full max-w-xs'>
        <label>Your Name (required)</label>
        <input className='p-2 outline-none border-b border-white focus:border-gray-400' />
    </div>

    <div className='flex flex-col w-full max-w-xs'>
        <label>Your Email (required)</label>
        <input className='p-2 outline-none border-b border-white focus:border-gray-400' />
    </div>

    <div className='flex flex-col w-full max-w-xs'>
        <label>Subject (required)</label>
        <input className='p-2 outline-none border-b border-white focus:border-gray-400' />
    </div>

    <div className='flex flex-col w-full max-w-xs'>
        <label>Your Message</label>
        <textarea
            cols='10'
            rows='10'
            className='resize-none border-b border-white focus:border-gray-400 outline-none p-2'
        ></textarea>
    </div>

   <button className='bg-[#ef233c] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3'>
              Send
            </button>
</form>

             </div>
             </div>
             
  );
}
export default Contact;

