import React from 'react'

import Navbar from '../Components/Navbar.js';

const iaaf= process.env.PUBLIC_URL + '/assets/images/iaaf2.png';


const Iatf = () => {
  return (
    <div className=' font-poppins'>
      <Navbar/>

      <div className='py-20 px-20 grid grid-cols-1 align-middle lg:grid-cols-2 gap-8 lg:gap-0  bg-[white] text-black'>
        <div className='relative inline uppercase '>
            <h1 className='font-bold text-[24px]'>Federal ministry of Industry Trade and Investment: TECHNICAL 
                Proposal to Host the Intra-African Trade Fair 2025</h1>
             <p>Industry / Sector: Public sector</p>
             <p>Launched: Year 2022</p>
        </div>
        <div>
                  <img className="w-[350px] h-[470px] object-cover mx-auto my-4 " src={iaaf} alt="/" />
            </div>
        </div>
        <div className='py-20 px-20 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 '>
            <p >Kwara State Government (KwSG) aspired to enhance and digitise 
                  its interaction with existing businesses and prospective investors 
                    using tech-enabled systems for better service delivery, citizen 
                  satisfaction and foster economic growth.
            </p>
        </div>
     <div className='py-20 px-20 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0  bg-[white] text-black'>
        <div className=' '>
            <h1 className='font-bold text-[24px]'>Results
                </h1>
             <div>
      <ul className="list-disc">
        <li> Effective problem-solving by providing practical 
              solutions that meet user and stakeholder needs.</li>
        <li>Impactful visual communication that effectively 
               conveys messages and captures audience attention</li>
        <li>Strong brand identity, differentiating the brand and 
               establishing a prominent market presence.</li>
               <li>Seamless functionality, offering user-friendly and 
                efficient experiences across products and platforms.</li>
                <li>
                  Promoting innovation and differentiation, bringing 
                   fresh ideas and uniqueness to the final product or 
                     brand.
                </li>
                <li>
                  Measurable success, allowing tracking and evaluation 
                    of project impact for future improvements.
                </li>
      </ul>
             </div>      
        </div>
        
        </div>
        <div className=' px-20 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 '>
          
            <div>
              <h1 className='
             font-bold text-[24px]'>Asteria service rendered:</h1>
      <ul className="list-disc">
        <li> Research & Strategy Development</li>
        <li>Event Organisation</li>
        <li>Service Design</li>
               <li>Print Design</li>
                <li>
                  Analytics
                </li>
               
      </ul>
    </div>
        </div>
    </div>
  )
}

export default Iatf;