import React from 'react';
import { Link } from 'react-router-dom'; 

const iaaf= process.env.PUBLIC_URL + '/assets/images/Iaaf.png';
const aqualandia= process.env.PUBLIC_URL + '/assets/images/aqualandia.png';
const kwara= process.env.PUBLIC_URL + '/assets/images/kwara.png';
const Bluenile= process.env.PUBLIC_URL + '/assets/images/bluenile.png';
const sebore= process.env.PUBLIC_URL + '/assets/images/sebore.png';

const items = [
  {
    imageSrc: iaaf,
    title: 'Architects Colloquium',
    description: 'Architects Registration Council of Nigeria: Architects Colloquium',
     route: '/Pages/Architecture', 
  },
  {
    imageSrc: aqualandia,
    title: 'Aqualandia',
    description: 'Central Park: Aqualandia',
    route: '/Pages/Aqualandia',
  },
  {
    imageSrc: kwara,
    title: 'Kwara State',
    description: 'Kwara State: Investment Promotion Enabling System.',
    route: '/Pages/Iatf',
    },
  {
    imageSrc: Bluenile,
    title: 'Bluenile',
    description: 'Bluenile',
     route: '/Pages/Bluenile',
  },
  {
    imageSrc: sebore,
    title: 'Sebore',
    description: 'Sebore',
    route: '/Pages/Sebore'
  },
  {
    imageSrc: iaaf,
    title: 'Iatf',
    description: 'FEDERAL MINISTRY OF INDUSTRY TRADE AND INVESTMENT: TECHNICAL PROPOSAL TO HOST THE INTRA-AFRICAN TRADE FAIR 2025',
    route: '/Pages/Iatf'
  },
];

const Clientele = () => {
  return (
    <div className="  font-poppins flex min-h-screen items-center justify-center py-16 bg-white">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        {items.map((item, index) => (
          <div
            key={index}
            className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30"
          >
            <div className="h-96 w-72">
              <img
                className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={item.imageSrc}
                alt=""
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/70 group-hover:to-black/70"></div>
            <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 className="font-dmserif text-3xl font-bold text-white">{item.title}</h1>
              <p className="mb-16 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                {item.description}
              </p>
              <Link  to={item.route} key={index} >
                <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                  See More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clientele;
